/* eslint-disable no-undef */
<script>
import Layout from '../../layouts/main'
import i18n from '@/i18n'
// import PageHeader from '@/components/page-header'
// import router from '../../router/routes.js'
/**
 * Starter page component
 */
export default {
  page: {
    title: i18n.t('index.title'),
    meta: [{ name: 'description' }],
  },
  components: { Layout },
  data() {
    var user = JSON.parse(localStorage.getItem('users'))
    return {
      title: 'index',
      items: [],
      profile_name: user[0].name,
      input_1: "",
      arr_1: [],
    }
  },
  methods:{
    routersClick(link) {
      // eslint-disable-next-line no-undef
      this.$router.push({ path: link })
    },
  },
}
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <b-container fluid>
      <b-row>
        <b-col class="text-center" style="font-size: 40px">
          <span>
            {{ $t('index.welcome') }}
            <!-- {{ $t('navbar.dropdown.kevin.list.logout') }} -->
          </span>
          <span class="font-color-blue">
            {{ profile_name }}
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center" style="font-size: 20px">
          <span>
            {{ $t('index.pls_select') }}
          </span>
        </b-col>
      </b-row>
      <b-row style="margin-top: 60px" class="text-center d-flex justify-content-center">
        <b-col lg="3" class="d-flex justify-content-center">
          <div header-class="bg-transparent" class="main-menu-big-btn" @click="routersClick('/register/goods_owner')">
            <img src="@/assets/images/clearance@2x.png" height="140px" class="margin-img" />
            <p class="card-text font-color-blue main-menu-big-btn-font">{{ $t('index.main_menu.goods') }}</p>
            <p class="main-menu-big-btn-font-sub">{{ $t('index.main_menu.goods_description') }}</p>
          </div>
        </b-col>
        <b-col lg="3" class="d-flex justify-content-center">
          <div header-class="bg-transparent" class="main-menu-big-btn" @click="routersClick('/register/transporter')">
            <img src="@/assets/images/shipping@2x.png" height="140px" class="margin-img" />
            <p class="card-text font-color-blue main-menu-big-btn-font">{{ $t('index.main_menu.transport') }}</p>
            <p class="main-menu-big-btn-font-sub">{{ $t('index.main_menu.transport_description') }}</p>
          </div>
        </b-col>
        <b-col lg="3" class="d-flex justify-content-center margin-display">
          <div header-class="bg-transparent" class="main-menu-big-btn" @click="routersClick('/register/goods_and_tran')">
            <img src="@/assets/images/clearance shipping@2x.png" height="70px" class="margin-img sm" />
            <p class="card-text font-color-blue main-menu-big-btn-font">{{ $t('index.main_menu.goods_and_transport') }}</p>
            <p class="main-menu-big-btn-font-sub">{{ $t('index.main_menu.goods_and_transport_description') }}</p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" class="d-flex justify-content-center" style="font-size: 18px; font-weight:300">
          <span>
            <br />
            <span class="font-color-blue">
              {{ $t('index.note') }}
            </span>
            <span>
              {{ $t('index.text') }}
            </span>
          </span>
        </b-col>
      </b-row>
    </b-container>
  </Layout>
</template>
<style>
.main-menu-big-btn {
  height: 25rem;
  width: 20rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #d8dbdf;
  border-radius: 5px;
  opacity: 1;
  margin-bottom: 1rem;
  padding: 15px;
}
.main-menu-big-btn:hover {
  transition: 0.3s;
  border: 1px solid #03AEEF;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.main-menu-big-btn-font {
  font-size: 1.5rem;
  font-weight: 500;
}
.main-menu-big-btn-font-sub {
  padding: 15px;
}
.margin-img {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}
.margin-img.sm{
  margin-top: 5rem;
  margin-bottom: 3.9rem;
}

</style>
